import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Introduction = ({ title, text, signature, image }) => {
  return (
    <Container>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <ParagraphContainer dangerouslySetInnerHTML={{ __html: text.html }} />
        <Signature>
          <Img
            style={{ width: "250px" }}
            fluid={signature.fluid}
            alt={signature.alt}
          />
        </Signature>
      </Content>
      <ImageContainer>
        <Img fluid={image.fluid} alt={image.alt} />
      </ImageContainer>
    </Container>
  )
}

export default Introduction

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 530px));
  grid-gap: 0 40px;
  padding: 40px 16px;
  justify-content: center;
  @media (max-width: 776px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`
const Content = styled.div`
  max-width: 630px;
  display: grid;
  align-content: baseline;
  color: var(--primary-text);
`
const Title = styled.div`
  font-size: 2rem;
  font-family: "Canela Light", sans-serif;
  h2 {
    margin: 0 0 1rem 0;
  }
`
const ParagraphContainer = styled.div`
  padding: 8px 0;
  p {
    margin: 0 0 1rem 0;
  }
`
const Signature = styled.div`
  @media (max-width: 1266px) {
    margin-bottom: 40px;
  }
`
const ImageContainer = styled.div`
  @media (max-width: 776px) {
    max-width: 400px;
  }
`
