import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const VacancyItem = ({ title, department, date, link }) => {
  return (
    <Container>
      <TableData>{title}</TableData>
      <TableData>{department}</TableData>
      <TableData>{date}</TableData>
      <TableData>
        <StyledLink to={link}>Details</StyledLink>
      </TableData>
    </Container>
  )
}

export default VacancyItem

const Container = styled.tr`
  &:nth-child(even) {
    background-color: #eceae8;
  }
`
const TableData = styled.td`
  color: var(--primary-text);
  padding: 16px 0;
  font-size: 0.95rem;
  &:first-child {
    width: 35%;
    padding-left: 16px;
  }
  &:last-child {
    width: 10%;
    text-align: center;
  }
`
const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  background: var(--primary-text);
  padding: 8px 16px;
  color: var(--primary-white);
  font-family: "Moderat Medium", sans-serif;
  transition: all 200ms ease;
  :hover {
    background: var(--primary-blue);
  }
`
