import React from "react"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../components/layout/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import BookNowBanner from "../components/home/book"
import Introduction from "../components/careers/introduction"
import Vacancies from "../components/careers/vacancies"
import Applications from "../components/careers/applications"

import { useCareersData } from "../hooks/useCareersData"

const componentMapper = {
  page_header: PageHeader,
  intro: Introduction,
  vacancies: Vacancies,
  applications: Applications,
  book: BookNowBanner,
}

const Careers = ({ data: previewData }) => {
  const { uid, bookData, sections } = useCareersData(previewData)

  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      {sections.map(section => {
        const Component = componentMapper[section.slice_type]
        return (
          <Component
            key={section.slice_type}
            {...section.primary}
            items={section.items}
          />
        )
      })}
    </Layout>
  )
}

export default withPreview(Careers)
