import React from "react"
import styled from "styled-components"
import VacancyItem from "./vacancyItem"

const Vacancies = ({ section_title, text, items }) => {
  const noVacancies = items.length === 0
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: section_title.html }} />
      <Text>
        {noVacancies
          ? "We currently don't have any open positions."
          : text.text}
      </Text>
      {noVacancies ? null : (
        <VacancyTable cellSpacing="0">
          <tbody>
            <tr style={{ width: "100%" }}>
              <TableHeader>Job Title</TableHeader>
              <TableHeader>Department</TableHeader>
              <TableHeader>Date</TableHeader>
            </tr>
            {items.map((vacancy, i) => (
              <VacancyItem key={i} link={vacancy.uid} {...vacancy.data} />
            ))}
          </tbody>
        </VacancyTable>
      )}
    </Container>
  )
}

export default Vacancies

const Container = styled.div`
  display: grid;
  max-width: 75%;
  margin: 0 auto;
  padding: 160px 0 80px 0;
`

const Title = styled.div`
  h2 {
    font-size: 3rem;
    font-family: "Canela Light", sans-serif;
    margin: 0 0 1rem 0;
    color: var(--primary-text);
    text-align: center;
  }
`

const Text = styled.p`
  max-width: 400px;
  text-align: center;
  margin: 0 auto 1rem auto;
  color: var(--primary-text);
`
const VacancyTable = styled.table`
  padding: 40px 0;
`
const TableHeader = styled.th`
  padding: 16px 0;
  &:first-child {
    padding-left: 16px;
  }
  color: var(--primary-text);
  text-align: left;
`
