import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useCareersData = previewData => {
  const staticData = useStaticQuery(
    graphql`
      query {
        prismicCareersPage {
          _previewable
          uid
          data {
            body {
              ... on PrismicCareersPageBodyPageHeader {
                slice_type
                primary {
                  title {
                    html
                  }
                  tagline {
                    text
                  }
                }
              }
              ... on PrismicCareersPageBodyIntro {
                slice_type
                primary {
                  title {
                    html
                  }
                  text {
                    html
                  }
                  signature {
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                    }
                    alt
                  }
                  image {
                    alt
                    fluid {
                      aspectRatio
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
              ... on PrismicCareersPageBodyVacancies {
                slice_type
                primary {
                  section_title {
                    html
                  }
                  text {
                    text
                  }
                }
              }
              ... on PrismicCareersPageBodyApplications {
                slice_type
                primary {
                  section_title {
                    html
                  }
                  text {
                    text
                  }
                  button_title {
                    text
                  }
                  button_text
                  button_link {
                    url
                    uid
                    link_type
                  }
                }
              }
              ... on PrismicCareersPageBodyBook {
                slice_type
                primary {
                  text {
                    text
                  }
                  button_text
                  link {
                    url
                    link_type
                  }
                }
              }
            }
          }
        }
        allPrismicVacancy {
          nodes {
            uid
            data {
              date(formatString: "DD-MM-YYYY")
              department
              title
            }
          }
        }
      }
    `
  )
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const { uid, data } = mergedData.prismicCareersPage
  const vacancies = mergedData.allPrismicVacancy.nodes
  const bookData = data.body.find(section => section.slice_type === "book")

  const sections = data.body.filter(section => section.slice_type !== "book")
  sections.forEach(section => {
    if (section.slice_type === "vacancies") {
      section.items = vacancies
    }
  })
  return {
    uid,
    bookData: bookData ? bookData.primary : undefined,
    sections,
  }
}
