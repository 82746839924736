import React from "react"
import styled from "styled-components"
import { Text } from "../atoms"
import { Link } from "gatsby"

const Applications = ({
  section_title,
  text,
  button_title,
  button_text,
  button_link,
}) => {
  const { url, link_type } = button_link
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: section_title.html }} />
      <Text>{text.text}</Text>
      <ButtonTitle>{button_title.text}</ButtonTitle>
      {link_type.toLowerCase() === "document" ? (
        <StyledLink to={url}>{button_text}</StyledLink>
      ) : (
        <Button
          href={button_link.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          {button_text}
        </Button>
      )}
    </Container>
  )
}

export default Applications

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  padding: 80px 0;
`
const Title = styled.div`
  h2 {
    font-size: 3rem;
    font-family: "Canela Light", sans-serif;
    margin: 0 0 1rem 0;
    color: var(--primary-text);
    text-align: center;
  }
`

const ButtonTitle = styled.p`
  color: var(--primary-text);
  margin: 0 0 0 0;
  font-family: "Moderat Bold", sans-serif;
  padding: 0 16px 0 0;
  @media (max-width: 776px) {
    text-align: center;
    padding: 0 16px;
  }
`

const Button = styled.a`
  text-decoration: none;
  margin: 16px 0;
  background: var(--primary-text);
  padding: 8px 24px;
  color: var(--primary-white);
  font-family: "Moderat Medium", sans-serif;
  border: 1px solid var(--primary-text);
  transition: all 200ms ease;
  :hover {
    color: var(--primary-text);
    background: var(--primary-white);
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 16px 0;
  background: var(--primary-text);
  padding: 8px 24px;
  color: var(--primary-white);
  font-family: "Moderat Medium", sans-serif;
  border: 1px solid var(--primary-text);
  transition: all 200ms ease;
  :hover {
    color: var(--primary-text);
    background: var(--primary-white);
  }
`
